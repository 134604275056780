@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../font/home-page/CabinetGrotesk-Regular.eot"), url("../../font/home-page/CabinetGrotesk-Regular.woff2") format("woff2"),
    url("../../font/home-page/CabinetGrotesk-Regular.woff") format("woff"), url("../../font/home-page/CabinetGrotesk-Regular.ttf") format("truetype"),
    url("../../font/home-page/CabinetGrotesk-Regular.svg#CabinetGrotesk-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../font/home-page/CabinetGrotesk-Medium.eot"), url("../../font/home-page/CabinetGrotesk-Medium.woff2") format("woff2"),
    url("../../font/home-page/CabinetGrotesk-Medium.woff") format("woff"), url("../../font/home-page/CabinetGrotesk-Medium.ttf") format("truetype"),
    url("../../font/home-page/CabinetGrotesk-Medium.svg#CabinetGrotesk-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cabinet Grotesk";
  src: url("../../font/home-page/CabinetGrotesk-Bold.eot"), url("../../font/home-page/CabinetGrotesk-Bold.woff2") format("woff2"),
    url("../../font/home-page/CabinetGrotesk-Bold.woff") format("woff"), url("../../font/home-page/CabinetGrotesk-Bold.ttf") format("truetype"),
    url("../../font/home-page/CabinetGrotesk-Bold.svg#CabinetGrotesk-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
