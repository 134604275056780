// colors
$gray: #ebebeb;
$white: #ffffff;
$black: #353535;
$gray-light: #c1cdcd;
$gray-dark: #616767;
$border-color: #e4e8ee;
$primary: #1d4066;
$blue: #1d4066;
$active-blue: #003b7d;
$blue-light: #0072b4;
$green: #1a865b;
$red: #ff4d4d;
$red-dark: #bf3d3d;
$bg-gray: #f8f9fa;
$green-light: #f5f9f3;
$accent-green: #00bdb7;
$orange: #ff5a00;
$yellow: #f5bf00;
$dark-gray: #182433bf;
$dark-blue: #182433e5;
$turquoise: #03fff6;

// variables
$border: 1px solid $border-color;
$border-radius-4: 4px;
$border-radius-2: 2px;

// fonts
$font-18: 18px;
$font-16: 16px;
$font-14: 14px;
$font-12: 12px;
$font-bold: 700;
$font-normal: 500;
$font-black: $black;
$font-blue: $blue;

// transition
$transition: 0.3s ease-in-out;
