/* You can add global styles to this file, and also import other style files */
@import "assets/css/vendors/tabler";
@import "assets/css/vendors/toast";
@import "assets/font/tabler-icons/tabler-icons";
@import "assets/css/base/typography";

@import "assets/css/base/variables";
@import "assets/css/base/base";
@import "assets/css/base/dropdown";
@import "assets/css/components/home";
