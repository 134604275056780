@import "../base/variables";

.toast-container .ngx-toastr {
  width: 400px;
  min-height: 70px;
  display: flex !important;
  align-items: center;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.12);
  padding: 15px 60px 15px 50px;
  border-radius: 0;
  background-position: 10px center;
  background-size: 28px;
  position: fixed;
  right: 20px;
  &:hover {
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 450px) {
    width: 350px;
  }
}

.toast-error {
  border-left: 5px solid $red;
  color: $red !important;
  background-color: $white;
  background-image: url("./../../../assets/img/toast/warning.svg");
  background-repeat: no-repeat;
}

.toast-info {
  border-left: 5px solid $blue;
  color: $blue !important;
  background-color: $white;
  background-image: url("./../../../assets/img/toast/info.svg");
  background-repeat: no-repeat;
}

.toast-warning {
  border-left: 5px solid $red;
  color: $red !important;
  background-color: $white;
  background-image: url("./../../../assets/img/toast/warning.svg");
  background-repeat: no-repeat;
}

.toast-success {
  border-left: 5px solid $green;
  color: $green !important;
  background-color: $white;
  background-image: url("./../../../assets/img/toast/success.svg");
  background-repeat: no-repeat;
}

button.toast-close-button {
  width: 70px;
  height: 70px;
  border: none;
  border-left: $border;
  right: 0;
  top: 0;
  position: absolute;
  background: url("./../../../assets/img/toast/close.svg") center center no-repeat;
  span {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: 1;
    outline: 0;
  }
}

.toast-top-right {
  top: 70px;
  right: 10px;
}
