@import "variables";

body {
  background: $white;
  color: var(--tblr-primary);
}

:root,
:host {
  --tblr-primary: #112d4f;
  --tblr-body-color: #112d4f;
}

.selectgroup-button {
  &--active {
    border-color: $primary;
    z-index: 1;
    color: $primary;
    background: #fff0ea;
  }
}

.custom-switch-lg {
  margin-bottom: 20px;

  .custom-switch-description {
    width: auto;
  }
}

.form-control-active {
  border-color: $primary;
  background: #fff0ea;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;

  &--second {
    top: 56px;
    z-index: 3;
  }

  &--third {
    top: 56px;
    z-index: 1;
  }

  @media (max-width: 1369px) {
    &--second-top {
      top: 84px;
    }
  }
}

.table-sticky {
  thead th {
    background: $white;
    position: sticky;
    top: 56px;
    z-index: 1;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
}

.list-group-item.active {
  z-index: 1 !important;
}

.blinker {
  display: inline-flex;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  animation-name: blinker;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  &--red {
    background: $red;
  }

  &--blue {
    background: $blue;
  }
}

.parameter-card {
  min-height: 130px;
  border: 1px dashed black;
  border-radius: 3px;
}

.graph {
  display: flex;
  height: 200px;
  position: relative;

  &__bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 7px;
    border-radius: 6px;
    background-color: $red-dark;
  }

  &__referent-max {
    right: 100%;
    transform: translateY(-50%);
  }

  &__referent-min {
    right: 100%;
    top: 100%;
    transform: translateY(-50%);
  }

  &__result {
    position: absolute;
    z-index: 1;
  }

  &__result-item-big {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &__result-item-small {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #fff;
    transition: opacity 0.3s;
  }

  .graph__result:hover &__result-item-small {
    opacity: 0;
  }

  &__dotted-line {
    width: 1px;
    border-left: 1px dashed $gray-light;
    opacity: 0;
    bottom: -5%;
    height: 110%;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  .graph__result:hover + &__dotted-line {
    opacity: 1;
  }

  &__optimized-max {
    left: 100%;
    transform: translateY(-50%);
  }

  &__optimized-min {
    left: 100%;
    top: 100%;
    transform: translateY(-50%);
  }

  &__tooltip {
    position: absolute;
    bottom: 140%;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    width: 150px;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 1);
    z-index: 2;
    padding: 5px;
  }

  .graph__result:hover .graph__tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .graph__result:hover {
    z-index: 3;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.scrollable {
  overflow: auto;
  height: 220px;

  &--xs {
    height: 175px;
  }

  &--sm {
    height: 345px;
  }

  &--md {
    height: 500px;
  }

  &--xl {
    height: 750px;
  }
}

.p-calendar {
  width: 100%;
}

.p-inputtext {
  @extend .form-control;
  width: 100%;
}

.page {
  background: #f6f8fb;
}

.negative-mt-70 {
  margin-top: -70px;
}

.negative-mr-90 {
  margin-right: -90px;
}

.negative-ml-90 {
  margin-left: -90px;
}

.accent-green {
  color: $accent-green;
}

.red-dark {
  color: $red-dark;
}

.orange {
  color: $orange !important;
}

.yellow {
  color: $yellow !important;
}

.white {
  color: $white !important;
}

.bg-white {
  background-color: $white !important;
}

.dark-gray {
  color: $dark-gray !important;
}

.bg-accent-green {
  background-color: $accent-green !important;
}

.bg-red-dark {
  background-color: $red-dark !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-yellow {
  background-color: $yellow !important;
}

.bg-dark-gray {
  background-color: $dark-gray !important;
}

.border-l-color-red-dark {
  border-left-color: $red-dark !important;
}

.border-l-color-orange {
  border-left-color: $orange !important;
}

.border-l-color-yellow {
  border-left-color: $yellow !important;
}

.border-l-color-accent-green {
  border-left-color: $accent-green !important;
}

.border-l-color-dark-gray {
  border-left-color: $dark-gray !important;
}

.border-color-red-dark {
  border-color: $red-dark !important;
}

.border-color-orange {
  border-color: $orange !important;
}

.border-color-yellow {
  border-color: $yellow !important;
}

.border-color-accent-green {
  border-color: $accent-green !important;
}

.progres-bar-divider-line {
  width: 4px;
  height: 28px;
  z-index: 20;
  border-radius: 3px;
  background-color: $black;
  margin-top: -2px;
}

.card-icon {
  background-color: $accent-green;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 3px;
}

.fs-36 {
  font-size: 36px;
  line-height: 40px;
}

.btn {
  text-wrap: unset;
}
