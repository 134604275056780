@import "variables";

.dropdown-custom {
  min-width: 150px;
  width: 100%;
  background: $white;
  position: relative;

  &__btn {
    min-height: 38px;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid rgba(0, 40, 100, 0.12);
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &__list {
    position: absolute;
    z-index: 100;
    width: 100%;
    max-height: 0;
    display: flex;
    flex-direction: column;
    background: $white;
    overflow: hidden;
    transition: max-height .2s ease-in-out;

    &--visible {
      max-height: 500px;
      overflow: auto;
      border-top: $border;
      box-shadow: 0 8px 27px 0 rgba(29, 64, 102, 0.22);
    }
  }

  &__item {
    display: block;
    padding: 10px;
    cursor: pointer;
    transition: $transition;
    margin-bottom: 0;

    &:hover {
      background: #eaebea;
    }
  }

  &__arrow {
    &--active {
      transform: rotate(180deg);
    }
  }

  .selectgroup__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }
}
