@import "../base/variables.scss";

body {
  font-family: "Cabinet Grotesk", sans-serif;
}

p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
}

.border-bottom-white {
  border-bottom: 1px solid $white;
}

.light-gray {
  color: #d9d9d9;
}

.language {
  margin-left: 20px;
  display: flex;
  cursor: pointer;

  &__icon {
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;

    &--en {
      background-image: url("../../../assets/img/en.png");
    }

    &--sr {
      background-image: url("../../../assets/img/sr.png");
    }
  }

  &__label {
    text-transform: uppercase;
    margin-left: 5px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.section {
  //padding: 40px 0;

  &__block {
    padding: 16px 32px;
    height: 100%;

    &-title {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    &-subtitle {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    &--grey {
      background: #ECF1F7;
      opacity: 0.8;
    }

    &--blue {
      background: $blue;
      opacity: 0.85;
      color: $white;
    }
  }

  &__image {
    //background-position: center center;
    //background-size: cover;
    //background-repeat: no-repeat;
    //height: 400px;
    margin-bottom: 16px;
  }

  &__image-container {
    height: 400px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background: #ECF1F7;
  }
}

.section-title {
  text-transform: uppercase;
  font-size: $font-16;
  font-weight: $font-bold;
  color: $accent-green;
}

.section-description {
  font-size: 42px;
  font-weight: $font-normal;
  margin-top: 16px;
  line-height: 1.3;
}

.section-description p {
  font-size: $font-18;
  font-weight: 400;
  margin-bottom: 16px;
}

.section-btn {
  background-color: $turquoise;
  border: none;
  width: fit-content;
  padding: 14px 30px;
  text-transform: uppercase;
  font-size: $font-18;
  font-weight: $font-bold;
  color: $primary;
  display: block;
  line-height: 1;

  &:hover {
    text-decoration: underline;
  }
}

.section-btn a {
  color: inherit;
  text-decoration: none;
}

.hero-section {
  background: url("../../img/home-page/hero.png") center center no-repeat $dark-blue;
  width: 100%;
  min-height: 900px;
  background-size: cover;

  &__container {
    margin-top: 300px;
  }

  &__supertitle {
    font-size: $font-16;
    font-weight: $font-normal;
    text-transform: uppercase;
    color: $accent-green;
  }

  &__title {
    font-size: 70px;
    text-transform: uppercase;
    line-height: 1;
    color: $white;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 20px;
    font-weight: 400;
    width: 50%;
    color: $white;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    min-height: 100vh;

    &__container {
      margin-top: 200px;
    }
    &__title {
      font-size: 42px;
    }
    &__description {
      font-size: 18px;
      width: 100%;
    }
  }
}

.background-grey {
  background: #ECF1F7;
}
.background-white {
  background: $white;
}
.background-blue {
  background: #112D4F;
  color: $white;
}

.features {
  display: flex;
  justify-content: space-between;
  gap: 60px;

  i {
    font-size: 28px;
    color: $accent-green;
  }

  h3 {
    font-size: $font-18;
    font-weight: $font-bold;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  p {
    font-size: $font-16;
    font-weight: 400;
    margin-bottom: 36px;
  }
}

.accordion {
  &__step {
    padding: 24px 0;
    border-top: 1px solid #dce1e7;
    font-size: $font-18;
  }

  &__step:nth-last-of-type(1) {
    border-bottom: 1px solid #dce1e7;
  }

  &__description {
    background-color: #112d4f;
    color: $white;
    padding: 32px;
    min-height: 500px;

    @media (max-width: 1023px) {
      padding: 16px;
      min-height: auto;
    }

    h3 {
      font-size: 32px;
      font-weight: $font-normal;
      margin-bottom: 24px;
      line-height: 1;
    }

    p {
      font-size: $font-18;
      font-weight: 400;
      line-height: 1.3;
    }

    ul {
      padding-left: 1rem;

      li {
        font-size: $font-18;
        font-weight: 400;
        line-height: 1.3;
        margin-bottom: 8px;
      }

      a {
        color: $white;
        text-decoration: underline;

        &:hover {
          color: $turquoise;
        }
      }
    }


    &--white {
      background-color: $white;
      color: #112d4f;
    }

    &--faq {
      background-color: #ECF1F7;
      color: #112d4f;
    }
  }

  &__img {
    height: 100%;
    object-fit: cover;
  }
}

.testimonial {
  border-bottom: 1px solid #dce1e7;
  padding-bottom: 24px;
  margin-bottom: 24px;

  &__pagination-dot {
    width: 6px;
    height: 6px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: #d9d9d9;
  }

  &__pagination-arrow {
    color: #d9d9d9;
    font-size: $font-16;
  }

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }

  p {
    font-size: 20px;
    font-weight: 400;
  }

  h3 {
    font-size: $font-18;
    font-weight: $font-bold;
    margin-bottom: 2px;
    line-height: 1;
  }

  h4 {
    font-size: $font-16;
    font-weight: $font-normal;
    color: #6c7a91;
    margin-bottom: 0;
  }
}

.card {
  border: 1px solid #dce1e7;
  padding: 0;

  &__image {
    width: 100%;
    min-height: 450px;
    background-size: cover;
    background-position: center center;
    position: relative;
    transition: .3s opacity;

    &:hover {
      .card__bio {
        opacity: 1;
      }
    }
  }

  &__bio {
    background: #112D4FBF;
    color: $white;
    display: flex;
    opacity: 0;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: .3s opacity;

    p {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: $font-bold;
    margin-bottom: 0 !important;
  }

  &__description {
    font-size: $font-16;
    font-weight: $font-normal;
    color: #6c7a91;
    margin-bottom: 0 !important;
  }
}

.min-h-105 {
  min-height: 105px;

  @media (min-width: 992px) and (max-width: 1230px) {
    min-height: 127px !important;
  }
}

.modules {
  position: relative;
  min-height: 400px;
  background: url("../../img/home-page/modul-bg-lg.svg") center center no-repeat;
  background-size: contain;

  @media (min-width: 992px) and (max-width: 1230px) {
    background: url("../../img/home-page/modul-bg-md.svg") center center no-repeat;
  }
  @media (max-width: 991px) {
    background: transparent;
  }

}

.origami {
  background: url("../../img/home-page/superhuman-origami.png") center center no-repeat;
  background-size: contain;
}

.static-page {
  background: #ECF1F7;

  &__links {
    position: sticky;
    top: 185px;
  }
  &__link {
    display: block;
    color: $primary;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 16px;
  }
}
